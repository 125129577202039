
<script setup>
    import { inject, computed } from "vue";

    const props = defineProps({
        text  : { type: String  , required: false },
        icon  : { type: String  , required: false },
        target: { type: Function, required: false },
        to    : {                 required: false },
        disabled: { type: Boolean, required: false },
        href  : { type: String, required: false },
    });

    const model = inject("action-sheet");

    const elementType = computed(() => {
        if (props.href || !props.to) {
            return "a";
        } else {
            return "router-link";
        }
    });

    function onClick(e) {
        console.log("click", e);
        model.value = false;
        //emit("click", e);
    }


</script>

<template>
    <component @click="onClick" :is="elementType" :to="to" :href="href" :ref="target" class="afm-action-sheet-btn" :class="{ 'justify-content-center': icon == null, 'disabled': disabled }" v-bind="$attrs">
        <template v-if="text">
            <div class="flex-shrink-0">
                {{ text }}
            </div>
        </template>
        <template v-if="icon">
            <div class="flex-grow-1 d-flex justify-content-end">
                <i class="fa-fw" :class="icon" />
            </div>
        </template>
    </component>
</template>

<style scoped>
    .afm-action-sheet-btn {
        display: flex;
        justify-content: center;

        >>> i {
            font-size: 1.25em;
        }

        &.disabled {
            pointer-events: none;
            cursor: default;
            background-color: var(--bs-gray-100);
            color: var(--bs-gray-500);
            pointer-events: none;
        }
    }
</style>
